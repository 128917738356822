.cube-box .front {
  transform: translateZ(100px);
}

.cube-box .back {
  transform: rotateX(-180deg) translateZ(100px);
}

.cube-box .right {
  transform: rotateY(90deg) translateZ(100px);
}

.cube-box .left {
  transform: rotateY(-90deg) translateZ(100px);
}

.cube-box .top {
  transform: rotateX(90deg) translateZ(100px);
}

.cube-box .bottom {
  transform: rotateX(-90deg) translateZ(100px);
}

.dice-container {
  width: 200px;
  height: 200px;
  position: relative;
  margin: 0 auto;
  border: 1px solid #fff;
  perspective: 11000px;
  perspective-origin: 50% 100%;
}

.cube-box {
  width: 100%;
  height: 100%;
  top: 0px;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 3s ease-out;
}

.cube-box:hover {
  cursor: pointer;
}

.cube-box div {
  background: var(--gsBlueDice);
  display: block;
  position: absolute;
  width: 200px;
  height: 200px;
  border: 2px solid var(--gsBlueDice);
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 500%;
  text-align: center;
  padding: 20px 0;
}

.dot {
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 15px;
}

.front .dot1 {
  top: 85px;
  left: 85px;
}
.back .dot1 {
  top: 45px;
  left: 45px;
}
.back .dot2 {
  top: 125px;
  left: 125px;
}
.right .dot1 {
  top: 45px;
  left: 45px;
}
.right .dot2 {
  top: 85px;
  left: 85px;
}
.right .dot3 {
  top: 125px;
  left: 125px;
}
.left .dot1 {
  top: 45px;
  left: 45px;
}
.left .dot2 {
  top: 45px;
  left: 125px;
}
.left .dot3 {
  top: 125px;
  left: 45px;
}
.left .dot4 {
  top: 125px;
  left: 125px;
}
.top .dot1 {
  top: 45px;
  left: 45px;
}
.top .dot2 {
  top: 45px;
  left: 125px;
}
.top .dot3 {
  top: 85px;
  left: 85px;
}
.top .dot4 {
  top: 125px;
  left: 45px;
}
.top .dot5 {
  top: 125px;
  left: 125px;
}
.bottom .dot1 {
  top: 45px;
  left: 45px;
}
.bottom .dot2 {
  top: 45px;
  left: 85px;
}
.bottom .dot3 {
  top: 45px;
  left: 125px;
}
.bottom .dot4 {
  top: 125px;
  left: 45px;
}
.bottom .dot5 {
  top: 125px;
  left: 85px;
}
.bottom .dot6 {
  top: 125px;
  left: 125px;
}

/* .dice-wrap {
  height: 100%;
} */
.dice-btn-wrap {
  text-align: center;
}
.dice-btn-wrap .btn-dice {
  text-align: center;
  background: var(--gsBlue);
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  color: var(--gsWhite);
  font-weight: 600;
  font-size: 20px;
}

.dice-btn-wrap .btn-dice-decline {
  text-align: center;
  background: red;
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  color: var(--gsWhite);
  font-weight: 600;
  font-size: 20px;
}

.dice-btn-wrap .btn-dice:focus {
  box-shadow: none;
}
.player {
  margin: auto;
}
.player-imageframe img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: auto;
  text-align: center;
}
.match-text {
  text-align: center;
}
.match-blue-text {
  color: var(--gsBlue);
  font-weight: 700;
}
.player .player-name {
  padding: 3rem 0;
}
.chat-time-left {
  text-align: right;
  padding: 10px 0;
}
.chat-time-left .countdown-time {
  color: var(--gsBlue);
  font-weight: 700;
}
.video-option-icon i {
  color: var(--gsBlue);
  font-size: 30px;
  padding: 0 20px;
}

button[type="submit"] {
  background: none;
  border: none;
  cursor: pointer;
}

.video-actions-wrapper {
  padding: 15px 0;
}
/* Toggle Switch */

.gs-custom-switch .custom-control-label::before {
  height: 1.2rem;
  width: 2.25rem;
  border: var(--gsBlack) solid 1px;
}
.gs-custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 1px);
  height: calc(1rem - 1px);
  border: var(--gsBlack) solid 1px;
  background-color: var(--gsBrightBlue);
}
.gs-custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(1rem);
  transform: translateX(1rem);
}
.gs-custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--gsBlack);
  background-color: var(--gsBlue);
}
.gs-custom-switch .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.gs-custom-switch
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--gsBlack);
}
.player-chat-room {
  position: relative;
}
.player-chat-wrap {
  border: 1px solid var(--gsGray);
  position: relative;
  height: 100%;
}
.oneto1-player-chat .popup-card-wrapper {
  border: 0;
  border-radius: 0;
}
.oneto1-player-chat .box-wrap-card {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  border: 0;
  border-radius: 0;
}
.oneto1-player-chat .card-body-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0;
  height: 100%;
}
.oneto1-player-chat .direct-chat-messages {
  height: 100%;
}
.gs-card-body-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0;
}

.video-chat-wrapper {
  .room {
    .participants {
      position: relative;
      min-height: 400px;
      background: #eee;
      .participant {
        &:first-of-type {
          position: absolute;
          z-index: 99;
          max-width: 25%;
          right: 0;
          bottom: 0;
        }
        .video {
          video {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.rolling_dice_checkbox {
  background: transparent;
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: none;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-bottom: 20px;

    li {
      display: inline-block;
      width: 25%;
      margin-bottom: 20px;
    }
  }

  [type="checkbox"]:checked + label,
  [type="checkbox"]:not(:checked) + label {
    position: relative;
    padding-left: 45px;
    cursor: pointer;
    line-height: 30px;
    display: inline-block;
    color: #143371;
    font-size: 18px;
    font-weight: bold;
  }

  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="checkbox"]:checked + label:before,
  [type="checkbox"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 33px;
    height: 33px;
    border: 3px solid #143371;
    border-radius: 10px;
    background: #fff;
  }

  [type="checkbox"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 33px;
    height: 33px;
    border: 3px solid #143371;
    border-radius: 10px;
    background: #fff;
  }
  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 11px;
    width: 11px;
    height: 21px;
    border: solid #143371;
    border-width: 0px 5px 5px 0;
    transform: rotate(45deg) !important;
  }
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .player-imageframe img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: auto;
    text-align: center;
  }

  .dice-container {
    width: 150px;
    height: 150px;
  }

  .cube-box div {
    width: 150px;
    height: 150px;
  }

  .cube-box .front {
    transform: translateZ(76px);
  }

  .cube-box .back {
    transform: rotateX(-180deg) translateZ(76px);
  }

  .cube-box .right {
    transform: rotateY(90deg) translateZ(76px);
  }

  .cube-box .left {
    transform: rotateY(-90deg) translateZ(76px);
  }

  .cube-box .top {
    transform: rotateX(90deg) translateZ(76px);
  }

  .cube-box .bottom {
    transform: rotateX(-90deg) translateZ(76px);
  }

  .dot {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 15px;
  }

  .front .dot1 {
    top: 65px;
    left: 65px;
  }

  .top .dot1 {
    top: 20px;
    left: 20px;
  }

  .top .dot2 {
    top: 20px;
    left: 113px;
  }

  .top .dot3 {
    top: 65px;
    left: 65px;
  }

  .top .dot4 {
    top: 112px;
    left: 20px;
  }

  .top .dot5 {
    top: 115px;
    left: 113px;
  }

  .bottom .dot1 {
    top: 20px;
    left: 20px;
  }
  .bottom .dot2 {
    top: 20px;
    left: 65px;
  }
  .bottom .dot3 {
    top: 20px;
    left: 113px;
  }

  .bottom .dot4 {
    top: 113px;
    left: 20px;
  }
  .bottom .dot5 {
    top: 113px;
    left: 65px;
  }
  .bottom .dot6 {
    top: 112px;
    left: 113px;
  }

  .left .dot1 {
    top: 20px;
    left: 20px;
  }

  .left .dot2 {
    top: 20px;
    left: 113px;
  }

  .left .dot3 {
    top: 113px;
    left: 20px;
  }

  .left .dot4 {
    top: 113px;
    left: 113px;
  }

  .right .dot1 {
    top: 30px;
    left: 30px;
  }

  .right .dot2 {
    top: 65px;
    left: 65px;
  }

  .right .dot3 {
    top: 100px;
    left: 100px;
  }

  .back .dot1 {
    top: 30px;
    left: 30px;
  }

  .back .dot2 {
    top: 100px;
    left: 100px;
  }
}
