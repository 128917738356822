.alice-carousel__prev-btn {
  position: absolute;
  top: 50%;
  left: 0;
  width: auto;
  transform: translateY(-50%);
  text-align: left;
}
.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  font-size: 26px;
  color: #fff;
  transform: scale(2, 8);
}
.alice-carousel__next-btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  text-align: left;
  width: auto;
}
