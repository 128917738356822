/*@font-face {
	font-family: "WeatherSunday-PersonalUse";
	src: url("./fonts/weather_sunday/WeatherSunday-PersonalUse.eot");
	src: url("styles/fonts/weather_sunday/WeatherSunday-PersonalUse.eot?#iefix")
		format("embedded-opentype"),
	  url("styles/fonts/weather_sunday/WeatherSunday-PersonalUse.svg#WeatherSunday-PersonalUse")
		format("svg"),
	  url("styles/fonts/weather_sunday/WeatherSunday-PersonalUse.ttf")
		format("truetype"),
	  url("styles/fonts/weather_sunday/WeatherSunday-PersonalUse.woff")
		format("woff"),
	  url("styles/fonts/weather_sunday/WeatherSunday-PersonalUse.woff2")
		format("woff2");
	font-weight: normal;
	font-style: normal;
  }
  @font-face {
	font-family: "Signika";
	src: url("styles/fonts/Signika/Signika-VariableFont_wght.ttf")
	  format("truetype");
	font-weight: normal;
	font-style: normal;
  }
  .weather_sunday {
	font-family: "WeatherSunday-PersonalUse";
  }*/
  .meet-xyz-wrap {
	/* font-family: "Signika"; */
  }
  h1.weather_sunday {
	transform: rotate(-20deg);
	-webkit-transform: rotate(-20deg);
	-moz-transform: rotate(-20deg);
	-ms-transform: rotate(-20deg);
	position: relative;
	top: 70px;
	margin-bottom: 0;
	font-size: 60px;
	color: var(--gsBlue);
	font-weight: 500;
	display: inline-block;
  }
  .filter-wrapper {
	margin-top: 5.5rem;
  }
  .filter-item-list {
	text-align: center;
	padding: 10px 0;
  }
  .filter-item-list a {
	text-transform: uppercase;
	font-size: 20px;
	border: 2px solid #707070;
	border-radius: 3px;
	color: #707070;
	padding: 4px 10px;
	margin: 0 3px;
	text-decoration: none;
	font-weight: 500;
  }
  .filter-item-list .inner-filter-items {
	border: 0;
	padding: 0;
  }
  .filter-item-list a.active {
	border: 2px solid var(--gsBlue);
	color: var(--gsBlue);
  }
  .profile-card-wrap {
	padding: 2rem 10px;
  }
  .meet-cards-list {
	display: grid;
	grid-template-columns: auto auto auto auto;
	height: 100%;
	grid-gap: 20px;
  }
  /* Profile style */
  .profile-card-wrapper {
	background: var(--gsWhite);
	padding: 10px;
	border: 1px solid var(--gsBlue);
	border-radius: 0.5rem;
	box-shadow: 1px 1px 10px #bbbbbb;
  }
  .profile-card-wrapper .card-header {
	background: transparent;
	border: 0;
	padding: 0;
  }
  .profile-card-wrapper .card-body {
	padding: 0;
  }
  .profile-card-wrapper .card-footer {
	padding: 0;
	background: transparent;
	border: 0;
  }
  .profile-card-wrapper .postion-wrap {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
  }
  .profile-card-wrapper .postion-wrap p {
	margin: 0;
	font-size: 16px;
	color: #707070;
  }
  .profile-card-wrapper .postion-wrap .status-on {
	color: #c1d82f;
	border: 2px solid var(--gsBlue);
	border-radius: 50%;
  }
  .profile-card-wrapper .employee-wrapper .working-place h5 {
	color: var(--gsBlue);
	font-weight: 500;
  }
  .employee-wrapper .employee-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
  }
  .employee-wrapper .emp-image-frame {
	height: 80px;
	width: 80px;
  }
  .employee-wrapper .emp-image-frame img {
	border-radius: 50%;
	border: 1px solid #707070;
  }
  .profile-card-wrapper .employee-wrapper .employee-name h2 {
	color: var(--gsBlue);
	font-weight: 500;
	margin-bottom: 25px;
  }
  .profile-card-wrapper .emp-contect-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 15px;
  }
  .profile-card-wrapper .emp-contect-info .emp-social-wrap a {
	background: var(--gsBlue);
	width: 40px;
	height: 40px;
	color: var(--gsWhite);
	padding: 2px 5px;
	margin-right: 5px;
	font-size: 20px;
	border-radius: 0.375rem;
	text-decoration: none;
  }
  .profile-card-wrapper .emp-contect-info .emp-video-profile a {
	text-decoration: none;
	font-size: 16px;
	padding: 5px 10px;
	color: var(--gsBlue);
	background: #c1d82f;
	border-radius: 0.375rem;
  }
  .profile-card-wrapper .emp-contect-info .emp-video-profile a i {
	margin-right: 5px;
  }
  .emp-load-more-wrap {
	text-align: center;
	padding: 3rem 0;
  }
  .emp-load-more-wrap .load-more-link {
	text-align: center;
	color: var(--gsBlue);
	border: 2px solid var(--gsBlue);
	font-size: 20px;
	font-weight: 600;
	border-radius: 0.375rem;
	padding: 5px 10px;
	text-transform: uppercase;
  }
  @media (max-width: 1050px) {
	.meet-cards-list {
	  grid-template-columns: auto auto !important;
	}
  }
  @media (max-width: 1365px) {
	.meet-cards-list {
	  grid-template-columns: auto auto auto;
	}
  }
  @media (min-width: 1920px) {
	.meet-cards-list {
	  grid-gap: 30px;
	}
	.profile-card-wrapper .emp-contect-info {
	  margin-top: 30px;
	}
	.profile-card-wrapper {
	  padding: 20px;
	}
	.employee-wrapper .emp-image-frame {
	  height: 120px;
	  width: 120px;
	}
	.profile-card-wrapper .postion-wrap p {
	  font-size: 22px;
	}
	.profile-card-wrapper .employee-wrapper .working-place h5 {
	  font-size: 30px;
	}
	.profile-card-wrapper .employee-wrapper .employee-name h2 {
	  font-size: 45px;
	  margin-bottom: 32px;
	}
	.profile-card-wrapper .emp-contect-info .emp-social-wrap a {
	  font-size: 30px;
	}
	.profile-card-wrapper .emp-contect-info .emp-video-profile a {
	  font-size: 24px;
	}
  }
  @media (min-width: 1200px) {
	.gs-modal-employee .modal-xl {
	  max-width: 90%;
	}
  }
  .gs-modal-employee .profile-page-wrap .profile-image-wrap {
	padding: 4rem 0;
  }
  .gs-modal-employee .profile-page-wrap .profile-image-wrap img {
	border: 1px solid #707070;
  }
  .calling-wrap .emp-calling {
	text-align: center;
	padding-bottom: 10px;
  }
  .calling-wrap .emp-calling h1 {
	text-align: center;
	text-transform: uppercase;
	color: var(--gsBlue);
	margin-bottom: 15px;
  }
  .calling-wrap .emp-calling a {
	text-decoration: none;
	font-size: 16px;
	font-weight: 600;
	text-transform: uppercase;
	padding: 5px 10px;
	color: var(--gsWhite);
	background: #ff3131;
	border-radius: 0.375rem;
  }
  .calling-wrap .emp-calling a i {
	-webkit-transform: rotate(225deg);
	-ms-transform: rotate(225deg);
	transform: rotate(225deg);
	margin: 0 10px 0 0px;
	font-size: 18px;
  }
  .call-connect-window {
	position: fixed;
	width: 100%;
	max-width: 320px;
	top: 2%;
	right: 2%;
  }
  .call-connect-window .calling-widget {
	background: var(--gsBlue);
	max-width: 300px;
	border-radius: 0.375rem;
  }
  .call-connect-window .calling-widget .calling-detail {
	text-align: center;
	padding: 5px 0;
  }
  .call-connect-window .calling-detail h4 {
	text-transform: uppercase;
	font-size: 20px;
	color: var(--gsWhite);
	font-weight: 600;
	margin-bottom: 0;
  }
  .call-connect-window .calling-detail h6 {
	text-transform: capitalize;
	font-size: 16px;
	color: var(--gsWhite);
  }
  .call-connect-window .calling-detail .calling-action-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
  }
  .call-connect-window .calling-action-wrap a {
	text-decoration: none;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	padding: 5px 10px;
	margin: 5px 10px;
	border-radius: 0.375rem;
  }
  .call-connect-window .calling-action-wrap a.calling-ans-btn {
	color: var(--gsWhite);
	background: #c1d82f;
  }
  .call-connect-window .calling-action-wrap a.calling-ans-btn i {
	font-size: 14px;
	margin: 0 10px 0 0px;
  }
  .call-connect-window .calling-action-wrap a.calling-decline-btn {
	color: var(--gsWhite);
	background: #ff3131;
  }
  .call-connect-window .calling-action-wrap a.calling-decline-btn i {
	-webkit-transform: rotate(225deg);
	-ms-transform: rotate(225deg);
	transform: rotate(225deg);
	margin: 0 10px 0 0px;
	font-size: 14px;
  }

  .globalChatLoader {
	display: flex;
    justify-content: center;
    height: 100%;
    align-items: flex-end;
    opacity: 0.5;
  }
  
  .globalChatNoMatch {
	display: flex;
    justify-content: center;
	align-items: flex-end;
  }