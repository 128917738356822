@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

body {
  font-family: "Poppins", sans-serif;
  transition: all 0.3s ease;
}
.wb-err-text h1 {
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 600;
  text-align: right;
  margin: 0;
  color: #49c168;
  padding: 0 5px;
  transition: all 0.3s ease;
}
.wb-err-content {
  max-width: 460px;
  margin: auto;
}
.wb-error-wrap {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
.wb-err404-text h1 {
  font-size: 250px;
  margin: 0;
  line-height: 0.8;
  width: auto;
  z-index: 1;

  position: relative;
  font-weight: 600;
  transition: all 0.3s ease;
}
.wb-oops-text {
  font-size: 40px;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
}
.wb-err404-text {
  position: relative;
  z-index: 1;
}
.wb-err404-text .wb-err404-bg {
  position: absolute;
  top: -30px;
  left: -20%;
  height: 100%;
  max-width: 350px;
  transition: all 0.3s ease;
}
@media (max-width: 1200px) {
  .wb-oops-text {
    font-size: 38px;
  }
  .wb-err404-text h1 {
    font-size: 240px;
  }
}
@media (max-width: 991px) {
  .wb-oops-text {
    font-size: 28px;
  }
  .wb-err404-text h1 {
    font-size: 175px;
  }
  .wb-err404-text .wb-err404-bg {
    max-width: 250px;
  }
}
@media (max-width: 767px) {
  .wb-err-content {
    max-width: 330px;
  }
}
@media (max-width: 575px) {
  .wb-err-content {
    max-width: 300px;
  }
  .wb-err404-text h1 {
    font-size: 120px;
  }
  .wb-err-content {
    text-align: center;
  }
  .wb-oops-text {
    font-size: 18px;
  }
  .wb-err-text h1 {
    font-size: 40px;
    padding: 0 10px;
    text-align: center;
    position: relative;
  }
  .wb-err404-text .wb-err404-bg {
    max-width: 200px;
  }
}
@media (max-width: 425px) {
  .wb-err404-text .wb-err404-bg {
    max-width: 160px;
    top: 0;
    left: 0;
  }
}
