/*============= Chat Boot Style =============*/

#contact-px-chat-box {
  position: fixed;
  z-index: 99999;
}

#contact-px-chat-box .contact-px-chat-box-iconwrapper {
  position: fixed;
  bottom: 0px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 0;
  cursor: pointer;
  outline: 0;
  width: 50px;
  height: 50px;
}

.contact-px-chat-box-icon .fa {
  font-size: 60px;
}

@media (max-width: 575px) {
  .contact-px-chat-box-icon .fa {
    font-size: 35px;
  }
}

@media (min-width: 576px) {
  #contact-px-chat-box .contact-px-chat-box-iconwrapper {
    bottom: 0px;
    right: 15px;
    width: 78px;
    height: 78px;
  }
}

#contact-px-chat-box
  .contact-px-chat-box-iconwrapper
  .contact-px-chat-box-icon {
  color: var(--gsBlue);
  width: 40px;
}

@media (min-width: 576px) {
  #contact-px-chat-box
    .contact-px-chat-box-iconwrapper
    .contact-px-chat-box-icon {
    width: 52px;
  }
}

#contact-px-chat-box .contact-px-chat-box-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
}

#contact-px-chat-box .contact-px-chat-box-content {
  position: fixed;
  bottom: 60px;
  right: 10px;
  background-color: var(--gsWhite);
  border-radius: 4px;
  padding: 20px 20px;
  display: none;
}

@media (min-width: 576px) {
  #contact-px-chat-box .contact-px-chat-box-content {
    bottom: 85px;
    right: 10px;
  }
}

#contact-px-chat-box .contact-px-chat-box-title {
  text-transform: capitalize;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  font-size: 20px;
  color: var(--gsBlue);
  padding-bottom: 20px;
  border-bottom: 1px solid #ced4da;
}

#contact-px-chat-box .contact-px-chat-box-title i {
  font-size: 20px;
  color: var(--gsBlue);
}

#contact-px-chat-box .contact-px-chat-box-linkicon {
  width: 38px;
  height: 38px;
  background-color: var(--gsWhite);
  border-radius: 100%;
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#contact-px-chat-box .contact-px-chat-box-linkicon i {
  color: var(--gsBlue);
  font-size: 20px;
}

#contact-px-chat-box .contact-px-chat-box-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0 0;
}

#contact-px-chat-box .contact-px-chat-box-link a {
  color: var(--gsBlack);
  text-decoration: none;
}

#contact-px-chat-box .contact-px-chat-box-arrow {
  position: absolute;
  top: 100%;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid var(--gsWhite);
}

@media (min-width: 576px) {
  #contact-px-chat-box .contact-px-chat-box-arrow {
    right: 30px;
  }
}

@media (min-width: 576px) {
  .faq-contact-chat-btn .contact-px-chat-box-iconwrapper {
    bottom: 0px;
    left: 15px;
    right: auto;
    width: 78px;
    height: 78px;
  }
}
.faq-contact-chat-btn .contact-px-chat-box-icon a {
  color: #091351;
}
