.login-form-wrapper {
  width: 100vw;
  height: 100vh;
  background: url(../../assets/Gs1/animationBG.png) center center no-repeat;
  background-size: 100% 100%;
  background-color: rgba(255, 255, 255, 0.8);
  background-blend-mode: overlay;
}
.login-section {
  display: table;
  width: 100%;
  height: 100%;
}
.gs-login {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.gs-logo-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0 30px;
}
.gs-logo-section .logo-img-wrap {
  width: 100px;
  height: auto;
  margin-right: 20px;
}
.gs-logo-section h1 {
  font-size: 40px;
  margin-bottom: 0;
  color: var(--gsBlue);
}
.login-form-wrapper form.login_form {
  max-width: 300px;
  margin: auto;
}
.login_form .form-control {
  border: 1px solid var(--gsGray);
  color: var(--gsBlack);
}
.login_form .form-control:focus {
  border-color: var(--gsBlue);
  box-shadow: none;
}
.login_form .input-group-text {
  border: 1px solid var(--gsBlue);
  background: var(--gsBlue);
  color: var(--gsWhite);
}

.login_form .input-group-text i {
  transform: scale(1.7);
}
.login_form .form-btn-wrap {
  margin-top: 3rem;
}
.login_form .btn-gs-login {
  background: var(--gsBlue);
  color: var(--gsWhite);
  font-size: 18px;
  font-weight: 700;
  border-radius: 0.4rem;
  outline: none;
}
.login_form .btn-gs-login:focus {
  box-shadow: none;
}

/* On Error */
.notice-component.warning {
  background: #ec0202;
  color: var(--gsWhite);
  padding: 5px 30px;
  border-radius: 0.375rem;
  margin-bottom: 20px;
}
/* Loader */
.login_form .btn-gs-login {
  background: var(--gsBlue);
  color: var(--gsWhite);
  font-size: 18px;
  font-weight: 700;
  border-radius: 0.4rem;
  outline: none;
  position: relative;
}
.login_form .btn-gs-login:focus {
  box-shadow: none;
}
.btn-gs-login .btn-spinner {
  position: absolute;
  left: 10px;
  top: 10px;
  /* transform: translateY(-50%); */
  width: 20px;
  height: 20px;
}

.btn-gs-login .btn-spinner {
  border: 0.15em solid currentColor;
  border-right-color: transparent;
}
